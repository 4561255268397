import AssessmentQuestion from "./AssessmentQuestion";

const CategoryQuestions = ({ category, number }) => {
  const categorySelfQuestions = category.questions.filter((question) => question.question.category.id === category.id);

  return (
    <div>
      {category.children ? (
        <div>
          {category.children.map((category, childIndex) => {
            const childNumber = `${number}.${childIndex + 1}`;
            return (
              <div key={category.id} className="pt-4">
                <h5 className="font-bold">
                  {childNumber}. {category.name}
                </h5>
                <CategoryQuestions key={category.id} number={childNumber} category={category} />
              </div>
            );
          })}
        </div>
      ) : null}

      {categorySelfQuestions.length > 0 ? (
        <div className="space-y-8 mt-4">
          {categorySelfQuestions.map((i, questionIndex) => (
            <div key={i.id}>
              <AssessmentQuestion assessmentQuestion={i} number={`${number}.${questionIndex + 1}`} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryQuestions;
