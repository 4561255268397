import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { FETCH_PRODUCT_STOCK_DETAIL } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import InventoryProductTableView from "./InventoryProductTableView";

const InventoryProductPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_STOCK_DETAIL, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page
      title={data.product.number}
      subtitle={data.product.name}
      backTo="./.."
    >
      <div>
        <div className="my-2 whitespace-nowrap text-sm">
          <InventoryProductTableView product={data.product} />
        </div>
      </div>
    </Page>
  );
};

export default InventoryProductPage;
