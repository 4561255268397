import Button from "components/Button";
import moment from "moment";
import { useModals } from "components/Modal";
import SubmitRectificationView from "./SubmitRecitifcationView";
import useAssessment from "./useAssessment";

const RectificationView = ({ assessmentQuestion }) => {
  const { assessmentStatus } = useAssessment();
  const modal = useModals();
  function submitHandler() {
    modal.present({
      title: "Submit Rectification 提交整改",
      children: <SubmitRectificationView assessmentQuestion={assessmentQuestion} dismiss={modal.dismiss} />,
    });
  }

  return (
    <div>
      <h6 className="mb-4 opacity-70 text-sm">Supplier Feedback 整改反馈</h6>
      {assessmentQuestion.rectifications.edges.length > 0 ? (
        <div className="-mt-4">
          {assessmentQuestion.rectifications.edges.map(({ node }) => (
            <div key={node.id} className="border-b dark:border-gray-700 py-2">
              <div className="text-sm opacity-70">{moment(node.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</div>
              <div className="whitespace-pre-wrap mt-2">{node.content}</div>

              {node.files.length > 0 ? (
                <div className="text-xs space-y-2 font-semibold mt-2">
                  {node.files.map((url, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <a href={url} target="_blank" rel="noreferrer">
                        {url}
                      </a>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}

      {assessmentStatus === "IN_RECTIFICATION" ? (
        <div className="mt-4">
          <Button onClick={submitHandler}>Submit 提交整改</Button>
        </div>
      ) : null}
    </div>
  );
};

export default RectificationView;
