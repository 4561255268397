import { gql } from "@apollo/client";

export const FETCH_ASSESSMENT = gql`
  query FETCH_ASSESSMENT($id: ID!) {
    assessment(id: $id) {
      id
      dueDate
      supplier {
        id
        name
      }
      baseInfo
      category {
        id
        name
        children {
          edges {
            node {
              id
              name
              requiredSupplier
              children {
                edges {
                  node {
                    id
                    name
                    requiredSupplier
                  }
                }
              }
            }
          }
        }
      }

      questions {
        edges {
          node {
            id
            question {
              id
              category {
                id
              }
              confirmRequired
              question
              answerType
              numberListCount
              choices {
                id
                choice
                content
              }
            }
            answerText
            answerNumbers
            answerChoices
            uploadedFiles
            score
            requiredRectification
            rectificationCompleted
            rectificationNote
            rectifications {
              edges {
                node {
                  id
                  content
                  updatedAt
                  files
                }
              }
            }
          }
        }
      }
      status
    }
  }
`;

export const SUBMIT_RECTIFICATION = gql`
  mutation SUBMIT_RECTIFICATION(
    $assessmentQuestionId: ID
    $rectificationId: ID
    $content: String
    $files: [String]
  ) {
    submitRectification(
      assessmentQuestionId: $assessmentQuestionId
      rectificationId: $rectificationId
      content: $content
      files: $files
    ) {
      assessmentQuestion {
        id
        rectifications {
          edges {
            node {
              id
              content
              updatedAt
              files
            }
          }
        }
      }
    }
  }
`;
