import TextareaAutosize from "react-autosize-textarea";

const Text = ({ className = "", ...rest }) => (
  <TextareaAutosize
    rows={3}
    className={`${className} w-full px-3 py-2 text-sm rounded-xl appearance-none border border-gray-200 focus:border-sky-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200`}
    {...rest}
  />
);

export default Text;
