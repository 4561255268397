import { useMutation } from "@apollo/client";
import { parseError } from "Apollo";
import { AppContext } from "AppContainer";
import { LOGOUT } from "auth/graphql";
import Button from "components/Button";
import Menu from "components/MainRouteLayout/Menu";
import { useContext } from "react";
import { BsLayoutSidebarInset } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import wabobaLogo from "assets/waboba-logo.png";
import wabobaLogoDark from "assets/waboba-logo-dark.png";

// This page is the home page of the app. It is the first page that is loaded when the user logs in.
// It is also the page that is loaded when the user clicks on the logo in the top left corner.

const IndexPage = () => {
  const { user, showMenu, setShowMenu } = useContext(AppContext);

  const [signOut, signOutRes] = useMutation(LOGOUT, {
    onCompleted() {
      window.location.href = "/login";
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  return (
    <div className="relative">
      <div className="md:hidden">
        <header className="p-6 border-b dark:border-gray-800 flex items-center justify-between">
          <div>
            <div className="opacity-70 text-sm italic">Waboba Supplier Portal</div>
            <Link to="/" className="dark:text-gray-100 dark:hover:text-gray-100">
              <h3>{user.supplier.name}</h3>
            </Link>
          </div>

          <div>
            <Button danger onClick={signOut} loading={signOutRes.loading}>
              Sign Out
            </Button>
          </div>
        </header>

        <Menu />
      </div>

      {showMenu ? null : (
        <div className="absolute z-10">
          <Button className="text-xl p-8" onClick={() => setShowMenu((prev) => !prev)}>
            <BsLayoutSidebarInset />
          </Button>
        </div>
      )}

      <div className="hidden md:flex flex-1 min-h-screen justify-center items-center bg-white dark:bg-gray-900 relative">
        <div className="p-8 text-center -mt-16">
          <div className="flex justify-center">
            <img className="h-12 dark:hidden" src={wabobaLogo} alt="Waboba" />
            <img className="h-12 hidden dark:inline-block" src={wabobaLogoDark} alt="Waboba" />
          </div>
          <h3 className="mt-8">Welcome to Waboba Supplier Portal</h3>
          <div className="mt-6 container">
            <p>
              Waboba build this portal to improve our workflow with our suppliers. We offer a couple of features that will help us to work together more
              efficiently.
            </p>
            <p>We hope you enjoy using this portal. If you have any questions, please contact us.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
