import { Navigate, Route, Routes } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "./AppContainer";
import IndexPage from "./pages";
import ESGPages from "./pages/esg";
import { toast } from "react-toastify";
import MainRouteLayout from "components/MainRouteLayout";
import ProductionPage from "pages/production";
import { LOGOUT } from "auth/graphql";
import Button from "components/Button";
import Support from "pages/support";
import Inventory from "pages/inventory";
import { BsFillExclamationCircleFill } from "react-icons/bs";

const AppRoutes = () => {
  const { user } = useContext(AppContext);

  console.log("user", user);

  if (user) {
    if (user.supplier) return <MainAppRoutes />;
    return <UserWithoutSupplier user={user} />;
  }

  window.location.href = process.env.REACT_APP_AUTH_SERVER + "?redirectTo=" + encodeURIComponent(window.location.href);

  return null;
};

const UserWithoutSupplier = ({ user }) => {
  const [signOut, signOutRes] = useMutation(LOGOUT, {
    onCompleted() {
      window.location.href = "/login";
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <div className="min-h-screen flex items-center justify-center w-full h-32">
      <div className="space-y-8 -mt-20 px-6">
        <div className="flex justify-center text-red-600">
          <BsFillExclamationCircleFill size={72} />
        </div>

        <h3 className="text-center">Waboba Supplier Portal</h3>

        <div className="text-center leading-loose max-w-3xl">
          This account({user.email}) is not associated with any supplier. Please contact Waboba system administrator to associate it with a supplier and then
          refresh the page to login! 此帐号还没有关联供应商，请联系Waboba系统管理员关联后再刷新页面登录！
        </div>
        <div className="flex justify-center space-x-8">
          <Button onClick={() => window.location.reload()}>Refresh 刷新页面</Button>
          <Button danger onClick={signOut} loading={signOutRes.loading}>
            Sign Out 退出登录
          </Button>
        </div>
      </div>
    </div>
  );
};

const MainAppRoutes = () => {
  return (
    <Routes>
      <Route element={<MainRouteLayout />}>
        <Route path="/" element={<IndexPage />} />
        <Route path="/esg/*" element={<ESGPages />} />
        <Route path="/production/*" element={<ProductionPage />} />
        <Route path="/inventory/*" element={<Inventory />} />
        <Route path="/support" element={<Support />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
