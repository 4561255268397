import { useLazyQuery, useMutation } from "@apollo/client";
import Input from "components/Input";
import { useState } from "react";
import { SAVE_ASSESSMENT_QUESTION } from "./graphql";
import { useDebouncedCallback } from "use-debounce";
import Spinner from "components/Spinner";
import Text from "components/Text";
import { FileSelector } from "components/FileSelector";
import Button from "components/Button";
import { BsTrash, BsUpload } from "react-icons/bs";
import { FETCH_PRESIGNED_URL, uploadFile } from "utils/upload";
import { toast } from "react-toastify";

function hasChoices(question) {
  return question.answerType === "SINGLE_CHOICE" || question.answerType === "MULTIPLE_CHOICES";
}

const AssessmentQuestion = ({ assessmentQuestion }) => {
  const numberListCount = assessmentQuestion.question.numberListCount;
  const [answer, setAnswer] = useState({
    answerNumbers: assessmentQuestion.answerNumbers.length ? assessmentQuestion.answerNumbers : Array(numberListCount).fill(0),
    answerText: assessmentQuestion.answerText,
    answerChoices: assessmentQuestion.answerChoices,
  });

  const [save, saveRes] = useMutation(SAVE_ASSESSMENT_QUESTION, {
    variables: { id: assessmentQuestion.id, ...answer },
  });
  const debouncedSave = useDebouncedCallback(save, 1000);

  const [fetchPresignedUrl] = useLazyQuery(FETCH_PRESIGNED_URL);
  const [isUploading, setIsUploading] = useState(false);

  function handleFileUpload(files) {
    setIsUploading(true);
    let promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((onCompleted, onError) => {
          fetchPresignedUrl({ variables: { filename: file.name } })
            .then((res) => uploadFile(res.data.qiniuPresignedUrl, file))
            .then(onCompleted)
            .catch(onError);
        })
      );
    });

    Promise.all(promises)
      .then((urls) => {
        save({ variables: { id: assessmentQuestion.id, ...answer, uploadedFiles: [...assessmentQuestion.uploadedFiles, ...urls] } });
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  function tryDeleteFile(url) {
    if (window.confirm("Are you sure to delete this file? 你确定要删除这个文件吗?") === false) return;
    const newUploadedFiles = assessmentQuestion.uploadedFiles.filter((u) => u !== url);
    console.log("newUploadedFiles", newUploadedFiles);
    save({ variables: { id: assessmentQuestion.id, ...answer, uploadedFiles: newUploadedFiles } });
  }

  return (
    <div>
      <div className="flex justify-between space-x-4">
        <div>
          <label htmlFor="">Question 问题:</label> {assessmentQuestion.question.question}
          {assessmentQuestion.question.answerType === "MULTIPLE_CHOICES" ? (
            <span className=" opacity-70">(Multiple Choice)</span>
          ) : assessmentQuestion.question.answerType === "SINGLE_CHOICE" ? (
            <span className=" opacity-70">(Single Choice)</span>
          ) : null}
        </div>

        <div
          className={`transition-all duration-300
          ${saveRes.loading ? "opacity-100" : "opacity-0"}`}
        >
          <Spinner size={16} />
        </div>
      </div>
      {hasChoices(assessmentQuestion.question) ? (
        <div className="my-2 space-y-2 ">
          {assessmentQuestion.question.choices.map((choice) => (
            <div key={choice.id} className="flex items-baseline space-x-3">
              <div
                className={`w-6 h-6 border dark:border-gray-400 rounded-full flex items-center justify-center flex-shrink-0 text-xs cursor-pointer
                ${
                  answer.answerChoices.includes(choice.choice)
                    ? "bg-blue-500  active:bg-blue-100 text-white active:text-gray-800 dark:bg-blue-600 dark:text-white dark:active:text-gray-400 dark:active:bg-gray-800"
                    : "active:bg-blue-600 hover:bg-blue-500"
                }
              `}
                onClick={() => {
                  let choices = [];
                  if (assessmentQuestion.question.answerType === "SINGLE_CHOICE") {
                    choices = [choice.choice];
                  } else if (assessmentQuestion.question.answerType === "MULTIPLE_CHOICES") {
                    if (answer.answerChoices.includes(choice.choice)) {
                      choices = answer.answerChoices.filter((c) => c !== choice.choice);
                    } else {
                      choices = [...answer.answerChoices, choice.choice];
                    }
                  }
                  setAnswer((prev) => ({ ...prev, answerChoices: choices }));
                  debouncedSave({ variables: { id: assessmentQuestion.id, ...answer, answerChoices: choices } });
                }}
              >
                {choice.choice}
              </div>
              <div>{choice.content}</div>
            </div>
          ))}
        </div>
      ) : assessmentQuestion.question.answerType === "TEXT" ? (
        <div className="mt-2">
          <Text
            placeholder="Input here. 请在此输入你的回答。"
            value={answer.answerText || ""}
            onChange={(e) => {
              const answerText = e.target.value;
              setAnswer((prev) => ({ ...prev, answerText }));
              debouncedSave({ variables: { id: assessmentQuestion.id, ...answer, answerText } });
            }}
          />
        </div>
      ) : numberListCount ? (
        <div className="mt-2">
          <div className="flex space-x-3 items-center">
            <label htmlFor="">Answer 答:</label>
            {answer.answerNumbers.map((i, index) => (
              <div key={index}>
                <Input
                  value={i}
                  onChange={(e) => {
                    const newAnswerNumbers = [...answer.answerNumbers];
                    newAnswerNumbers[index] = e.target.value;
                    setAnswer((prev) => ({ ...prev, answerNumbers: newAnswerNumbers }));
                    debouncedSave({
                      variables: { id: assessmentQuestion.id, ...answer, answerNumbers: newAnswerNumbers },
                    });
                  }}
                  className="text-center"
                />
              </div>
            ))}
          </div>
          <div className=" text-xs opacity-70 mt-2">Please input the answer in order. 请按顺序输入答案。</div>
        </div>
      ) : (
        <div className="flex items-baseline mt-1 space-x-2">
          <label htmlFor="">Answer: </label>
          <div>{assessmentQuestion.answer}</div>
        </div>
      )}

      <div className="text-sm font-semibold mt-4 space-y-2">
        {assessmentQuestion.uploadedFiles.length > 0 ? (
          <div className="text-xs space-y-2">
            {assessmentQuestion.uploadedFiles.map((url, index) => (
              <div key={index} className="flex items-center space-x-2">
                <Button danger onClick={() => tryDeleteFile(url)}>
                  <BsTrash />
                </Button>
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </div>
            ))}
          </div>
        ) : null}

        <FileSelector
          uploading={isUploading}
          title={
            <div className="flex items-center space-x-2">
              <span>
                <BsUpload />
              </span>
              <span>Upload file / image</span>
            </div>
          }
          onChange={(e) => {
            const files = [...e.target.files];
            handleFileUpload(files);
            e.target.value = null;
          }}
        />
      </div>
    </div>
  );
};

export default AssessmentQuestion;
