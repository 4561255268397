import { Link } from "react-router-dom";
import { BsChevronLeft, BsLayoutSidebarInset } from "react-icons/bs";
import { AppContext } from "AppContainer";
import { createContext, useContext, useRef } from "react";
import Button from "./Button";

export const PageContext = createContext();

const Page = ({ title, subtitle, backTo = "/", topbarRightView, children, containerClass = "flex flex-col flex-1" }) => {
  const { showMenu, setShowMenu } = useContext(AppContext);
  const pageRef = useRef();

  return (
    <div className="h-screen flex-1 flex flex-col bg-gray-50 dark:bg-gray-900 overflow-auto" id="page" ref={pageRef}>
      <nav className="bg-gray-50 dark:bg-gray-900 border-b dark:border-gray-700 flex justify-between items-center">
        <div className="flex flex-1 items-center">
          <div
            className={`transition-all duration-300 overflow-hidden
            ${showMenu ? "opacity-0 w-0" : "opacity-100 w-14"}`}
          >
            <Button className={`text-xl px-8 pr-0`} onClick={() => setShowMenu((prev) => !prev)}>
              <BsLayoutSidebarInset />
            </Button>
          </div>
          {backTo ? (
            <Link to={backTo} className="flex items-center justify-center space-x-2 p-6">
              <BsChevronLeft size={21} />
            </Link>
          ) : null}
          <div className="py-6">
            <h2 className={`${backTo ? "" : "pl-6 md:pl-8"}`}>{title}</h2>
            {subtitle ? <div className="">{subtitle}</div> : null}
          </div>
        </div>
        {topbarRightView ? <div className="flex items-center justify-center space-x-2 px-6 md:px-10">{topbarRightView}</div> : null}
      </nav>
      <PageContext.Provider value={{ pageRef }}>
        <div className={containerClass}>{children}</div>
      </PageContext.Provider>
    </div>
  );
};

export default Page;
