import Page from "components/Page";
import { Link, Route, Routes } from "react-router-dom";
import AssessmentDetail from "./AssessmentDetail";
import { useQuery } from "@apollo/client";
import { FETCH_SUPPLIER_ASSESSMENTS } from "./graphql";
import Spinner from "components/Spinner";
import Status from "components/Status";
import Errors from "components/Errors";
import AssessmentFollow from "./followup";
import moment from "moment";

const CSRPage = () => {
  return (
    <Routes>
      <Route index element={<ESGIndex />} />
      <Route path=":id/category/*" element={<AssessmentDetail />} />
      <Route path=":id/follow" element={<AssessmentFollow />} />
    </Routes>
  );
};

const ESGIndex = () => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_ASSESSMENTS);
  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const assessments = data.me.supplier.assessmentSet.edges.filter((i) => i.node.status !== "DRAFT");

  if (assessments.length === 0) {
    return (
      <Page title="ESG" containerClass="flex flex-col flex-1">
        <div className="h-full flex flex-col justify-center items-center">
          <div className="mb-5 text-center opacity-70 p-8">
            We don't have any assessments for your supplier at the momment. <br />
            我们目前没有任何关于您供应商的评估。
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page title="ESG">
      <div className="m-6 lg:m-8 flex flex-col h-full">
        <div className="opacity-70 text-sm mx-2">
          ESG stands for Environmental, Social and Corporate Governance, which is very important to us. We want to make sure that our suppliers are compliant
          with our ESG standards. This page list all the assessments that we have sent to you.
          <br />
          ESG代表环境、社会和公司治理，这些对我们很重要。 我们希望确保我们的供应商符合我们的ESG标准。 此页面列出了我们发送给您的所有评估。
        </div>

        <div className="lg:py-4 card flex-1 mt-6">
          {assessments.map(({ node }) => {
            const baseInfo = JSON.parse(node.baseInfo);

            return (
              <div key={node.id} className="flex space-x-4 items-center py-2 border-b border-gray-100 dark:border-gray-700">
                <div className="flex-1">
                  <div className="lg:flex lg:space-x-4 lg:items-baseline">
                    <Link to={node.status === "SELF_ASSESSMENT" ? `${node.id}/category/0` : `${node.id}/follow`}>{node.category.name}</Link>
                    <div className="opacity-70 text-sm">Due date: {moment(node.dueDate).format("YYYY-MM-DD")}</div>
                  </div>
                  {baseInfo && baseInfo.name && <div className="text-sm">{baseInfo.name}</div>}
                </div>

                <div className="flex items-center space-x-4">
                  <Status status={node.status} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default CSRPage;
