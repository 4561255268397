import moment from "moment";

const Calendar = ({ year, setYear, selectedDates, setSelectedDates }) => {
  const months = [...Array(12).keys()].map((monthIndex) => {
    const month = moment({ year, month: monthIndex });
    const dates = [...Array(month.daysInMonth()).keys()]
      .map((dateIndex) => ({
        date: moment({ year, month: monthIndex, date: dateIndex + 1 }),
      }))
      .map((i) => ({
        ...i,
        used: selectedDates.includes(i.date.format("YYYY-MM-DD")),
      }));
    const indentDays = month.day();
    return { month, dates, indentDays };
  });

  const weekNames = ["日", "一", "二", "三", "四", "五", "六"];

  const today = moment();

  const thisYearAndNextYear = [today.year(), today.year() + 1];

  return (
    <div className="flex flex-col h-full bg-white dark:bg-gray-800 rounded-2xl">
      <div className="flex-1 px-8 pt-8 flex flex-col">
        <div className="flex space-x-3">
          <div className="text-3xl">
            <select value={year} onChange={(e) => setYear(e.target.value)} className="bg-transparent -ml-2">
              {thisYearAndNextYear.map((i) => (
                <option key={i} value={i}>
                  {`${i}`}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-12 mt-8 flex-1">
          {months.map(({ month, dates, indentDays }) => (
            <div key={month}>
              <div className="grid grid-cols-7 text-center gap-3">
                <div className="text-2xl text-red-500 w-6">{month.format("MMMM")}</div>
                <div className="col-span-6"></div>

                {[...Array(7).keys()].map((i) => (
                  <div className="opacity-60 w-5 h-5" key={i}>
                    {weekNames[i]}
                  </div>
                ))}
                {[...Array(indentDays).keys()].map((i) => (
                  <div key={i}></div>
                ))}
                {dates.map((i, index) => (
                  <div
                    className={`font-semibold rounded-full w-6 h-6 flex items-center justify-center cursor-pointer hover:bg-blue-500 hover:text-white active:bg-blue-600 active:text-white
                           ${today.isSame(i.date, "day") ? "bg-red-500 text-white dark:text-gray-100" : "1"}
                           ${i.used ? "bg-blue-500 text-white dark:text-gray-100" : ""}
                        `}
                    key={index}
                    onClick={() => {
                      if (i.used) {
                        setSelectedDates(selectedDates.filter((j) => j !== i.date.format("YYYY-MM-DD")));
                      } else {
                        setSelectedDates([...selectedDates, i.date.format("YYYY-MM-DD")]);
                      }
                    }}
                  >
                    {i.date.format("D")}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
