import Spinner from "components/Spinner";
import { IoIosSend } from "react-icons/io";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const Button = ({ className = "", children, loading, next, prev, send, icon, primary, danger, ...rest }) => {
  return (
    <button
      className={`font-semibold opacity-90 hover:opacity-100 whitespace-nowrap disabled:cursor-not-allowed disabled:opacity-50 flex items-center space-x-2
          ${
            primary
              ? "bg-blue-600 hover:bg-blue-700 active:bg-blue-800 text-gray-100 px-6 py-2 text-sm rounded-full"
              : danger
              ? "text-red-600 hover:text-red-700 active:text-red-800 dark:text-red-700 dark:hover:text-red-800 dark:active:text-red-900"
              : "text-blue-600 hover:text-blue-700 active:text-blue-800"
          }
          ${className}
        `}
      {...rest}
      disabled={loading || rest.disabled}
    >
      {loading ? <Spinner size={16} text={null} /> : prev ? <IoChevronBack /> : icon ? icon : null}
      {children ? <div>{children}</div> : null}
      {next ? <IoChevronForward /> : null}
      {send ? (
        <span className="rotate-12">
          <IoIosSend size={21} />
        </span>
      ) : null}
    </button>
  );
};

export default Button;
