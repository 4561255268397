import { gql } from "@apollo/client";

export const SUBMIT_PRODUCTION_FORM = gql`
  mutation SUBMIT_PRODUCTION_FORM($data: String!) {
    submitProductionForm(data: $data) {
      message
    }
  }
`;

export const SUBMIT_SUPPLIER_OFF_DATES = gql`
  mutation SUBMIT_SUPPLIER_OFF_DATES($offDates: [Date]!) {
    submitSupplierOffDates(offDates: $offDates) {
      supplier {
        id
        offDates {
          id
          date
          remark
        }
      }
    }
  }
`;
export const FETCH_SUPPLIER_OFF_DATES = gql`
  query FETCH_SUPPLIER_OFF_DATES {
    me {
      supplier {
        id
        offDates {
          id
          date
        }
      }
    }
  }
`;
