import { useQuery } from "@apollo/client";
import { FETCH_SUPPLIER_STOCK_PRODUCTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { parseError } from "Apollo";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { searchByProp } from "utils/search";

const ProductSelector = ({ onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_STOCK_PRODUCTS);
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const products = data.supplierStockProducts;

  const productsToShow = products.filter((product) =>
    searchByProp(product, ["name", "number"], searchText),
  );

  return (
    <div className=" space-y-4">
      {products.length > 10 && (
        <div>
          <SearchBar
            autoFocus
            placeholder="Search by name or item number."
            value={searchText}
            onChange={setSearchText}
          />
        </div>
      )}

      <table>
        <tbody>
          {productsToShow.map((product) => (
            <tr
              className="border-y cursor-pointer hover:text-blue-600"
              key={product.id}
              onClick={() => onSelect(product)}
            >
              <td>
                <div>
                  {/* We will add images later. */}
                  {/* {product.images.length > 0 && (
                    <div>
                      <img src={product.images[0].url} alt={product.name} className="w-8 h-8 rounded-full" />
                    </div>
                  )} */}
                  <div>
                    [{product.number}] {product.name}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductSelector;
