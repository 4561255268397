import { useMutation } from "@apollo/client";
import Button from "components/Button";
import Input from "components/Input";
import { Select } from "components/Select";
import { useState } from "react";
import { toast } from "react-toastify";
import { SUBMIT_PRODUCTION_FORM } from "./graphql";
import { BsTrash } from "react-icons/bs";
import { BY_TYPES, WEEK_DAYS } from "./consts";
import { Tag } from "components/Tag";

const EditView = ({ item, dismiss }) => {
  const [workDays, setWorkDays] = useState({
    Monday: item.workOnMonday,
    Tuesday: item.workOnTuesday,
    Wednesday: item.workOnWednesday,
    Thursday: item.workOnThursday,
    Friday: item.workOnFriday,
    Saturday: item.workOnSaturday,
    Sunday: item.workOnSunday,
  });

  const [type, setType] = useState(item.type);
  const [qtyPerDay, setQtyPerDay] = useState(item.qtyPerDay);
  const [timeList, setTimeList] = useState(item.timeList);
  const [maxCapacity, setmaxCapacity] = useState(item.maxCapacity);
  const [submitProductionForm, submitProductionFormRes] = useMutation(SUBMIT_PRODUCTION_FORM, {
    onCompleted() {
      toast.success("提交成功！我们会尽快处理您的请求!");
      dismiss();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  console.log("item", item);

  function submit() {
    if (type === "BY_QTY") {
      if (timeList.length === 0) {
        toast.error("还未添加任何生产计划");
      }
      if (timeList.length !== 0) {
        //判断是否为0
        const temp1 = timeList.every((item) => item.qty !== 0 && item.days !== 0);
        if (!temp1) {
          toast.warning("生产数目和所需天数不可以为0");
          return;
        }
        //判断是否为空
        const temp2 = timeList.every((item) => item.qty !== "" && item.days !== "");
        if (!temp2) {
          toast.warning("生产数目和所需天数不可以为空");
          return;
        }
        //判断是否为正整数、纯数字
        const temp3 = timeList.every((item) => {
          return !isNaN(item.qty) && !isNaN(item.days);
        });
        if (!temp3) {
          toast.warning("生产数目和所需天数不可以为0");
          return;
        } else {
          const weekDays = Object.entries(workDays).reduce((acc, [key, value]) => {
            acc["workOn" + key] = value;
            return acc;
          }, {});
          const data = JSON.stringify({ id: item.id, name: item.name, type, maxCapacity, timeList, qtyPerDay, ...weekDays });
          submitProductionForm({ variables: { data } });
        }
      }
    }

    if (type === "BY_DAY") {
      if (qtyPerDay === 0) {
        toast.error("每日生产数目不可以为0");
        return;
      }
      if (isNaN(qtyPerDay)) {
        toast.warning("请输入合法的生产个数");
        return;
      } else {
        const weekDays = Object.entries(workDays).reduce((acc, [key, value]) => {
          acc["workOn" + key] = value;
          return acc;
        }, {});
        const data = JSON.stringify({ id: item.id, type, name: item.name, maxCapacity, timeList, qtyPerDay, ...weekDays });
        submitProductionForm({ variables: { data } });
      }
    }
  }

  function updateTime(index, key, value) {
    const newList = timeList.map((i, prevIndex) => {
      if (prevIndex === index) return { ...i, [key]: Number(value) };
      return i;
    });
    setTimeList(newList);
  }
  function deleteListItem(index) {
    let newTimelist = [...timeList];
    newTimelist.splice(index, 1);
    setTimeList(newTimelist);
  }
  function addTime() {
    setTimeList([...timeList, { qty: "", days: "" }]);
  }

  return (
    <div>
      <div className="card-in-modal px-8 py-6 mt-4">
        <h4>生产时间</h4>
        <div className="flex items-center mt-4">
          <label className="pr-3 ">计算类型: </label>
          <Select className="dark:bg-gray-800" value={type} onChange={(e) => setType(e.target.value)}>
            {Object.entries(BY_TYPES).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Select>
        </div>
        <div className="mt-4">
          {type === "BY_DAY" ? (
            <div className="flex space-x-3 items-center ">
              <div>
                <label className="">生产数量:</label>
              </div>
              <div>
                <Input
                  className="text-center"
                  value={isNaN(qtyPerDay) ? "" : qtyPerDay}
                  onChange={(e) => {
                    setQtyPerDay(Number(e.target.value));
                  }}
                />
              </div>
              <span className="whitespace-nowrap">个/天</span>
            </div>
          ) : (
            <div className="w-full">
              {timeList.map((time, index) => (
                <div key={index} className="flex space-x-6 mt-3">
                  <div className="flex items-center space-x-2">
                    <div className="flex flex-col lg:flex-row lg:items-center">
                      <label className={index === 0 ? "mb-3 lg:mb-0 lg:mr-3" : "hidden lg:inline-block lg:mr-3"} htmlFor="">
                        生产数量:{" "}
                      </label>
                      <div className="flex-1">
                        <Input
                          className="text-center"
                          tag="number"
                          value={isNaN(time.qty) ? 0 : time.qty}
                          onChange={(e) => {
                            updateTime(index, "qty", e.target.value);
                          }}
                          placeholder="数量"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:space-x-0 lg:items-center">
                    <label className={index === 0 ? "mb-3 lg:mb-0 lg:mr-3" : "hidden lg:inline-block lg:mr-3"} htmlFor="">
                      所需天数:{" "}
                    </label>
                    <div className="flex-1 flex items-center">
                      <Input
                        className="text-center"
                        tag="number"
                        value={isNaN(time.days) ? 0 : time.days}
                        onChange={(e) => {
                          updateTime(index, "days", e.target.value);
                        }}
                        placeholder="天数"
                      />
                      <Button className="ml-4" danger onClick={() => deleteListItem(index)}>
                        <BsTrash />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="mt-4">
                <Button onClick={addTime}>+ 添加新数量</Button>
              </div>
            </div>
          )}
        </div>
        <div className="flex lg:flex-row space-x-3 items-center mt-4">
          <label>最大月产能:</label>
          <div>
            <Input
              className="text-center"
              placeholder="每月最大生产数量"
              value={isNaN(maxCapacity) ? "" : maxCapacity}
              onChange={(e) => {
                setmaxCapacity(Number(e.target.value));
              }}
            />
          </div>
          <span>个 / 月</span>
        </div>
      </div>

      <div className="card-in-modal px-8 py-6 mt-8">
        <h4 className="">工作日:</h4>
        <div className="flex flex-wrap mt-4">
          {Object.entries(WEEK_DAYS).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between xl:justify-start border-b xl:border-0 border-gray-100 mr-4 mb-4">
              <Tag
                selected={workDays[key]}
                onClick={() => {
                  setWorkDays({ ...workDays, [key]: !workDays[key] });
                }}
              >
                {value}
              </Tag>
            </div>
          ))}
        </div>
        <div className="opacity-70 text-sm">选择工作日有助于我们更精确的估算生产时间。</div>
      </div>

      <div className="mt-8">
        <Button className="ml-2" send onClick={submit} loading={submitProductionFormRes.loading}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EditView;
