import { useMutation } from "@apollo/client";
import Button from "components/Button";
import { SUBMIT_ASSESSMENT } from "./graphql";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { parseError } from "Apollo";

const ConfirmSubmitView = ({ assessment, dismiss }) => {
  const questions = assessment.questions.edges.filter(({ node }) => {
    return !!node.answerAt;
  });
  console.log("assessment", questions, assessment.questions.edges.length);
  const questionsNotAnswered = assessment.questions.edges.length - questions.length;

  const navigate = useNavigate();
  const [submitAssessment, submitAssessmentRes] = useMutation(SUBMIT_ASSESSMENT, {
    variables: { id: assessment.id, status: "reviewing" },
    onCompleted() {
      toast.success("Assessment submitted 提交成功");
      navigate("/esg");
    },
    onError(err) {
      toast.error(parseError(err));
    },
  });

  return (
    <div>
      <div className="space-y-4">
        {questionsNotAnswered > 0 && (
          <div>
            There are {questionsNotAnswered} questions not answered. If you are sure that the questions not answered do not need to be answered, you can submit.
            还有 {questionsNotAnswered} 个问题未回答。如果你确定未回答的问题不需要回答，你可以提交。
          </div>
        )}

        <div>Are you sure you want to submit this assessment? Once you submit, you cannot edit it anymore. 你确定要提交这个评估吗？提交后将无法再修改。</div>
      </div>

      <div className="mt-8 space-x-6 flex">
        <Button onClick={submitAssessment} loading={submitAssessmentRes.loading}>
          Confirm Submit 确认提交
        </Button>
        <Button onClick={dismiss}>Cancel 取消</Button>
      </div>
    </div>
  );
};

export default ConfirmSubmitView;
