import Page from "components/Page";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { SUBMIT_FEEDBACK } from "./graphql";
import { toast } from "react-toastify";
import Button from "components/Button";
import Text from "components/Text";

const Support = () => {
  const [message, setMessage] = useState("");
  const [submitFeedback, submitFeedbackRes] = useMutation(SUBMIT_FEEDBACK, {
    onCompleted() {
      toast.success("Submit Success. 提交成功, 我们会尽快处理您的请求!");
      setMessage("");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  function submit() {
    if (message === "") {
      toast.error("Please input the message. 请输入内容");
      return;
    }
    submitFeedback({ variables: { message, subject: "Supplier feedback" } });
  }
  return (
    <div>
      <Page title="Support" containerClass="flex justify-center xl:items-center xl:flex-1">
        <div className="p-8 container max-w-3xl text-center xl:-mt-20">
          <h2>We are happy to hear from you!</h2>
          <div className="opacity-70 mt-4 space-y-2">
            <p>
              Your feedback matters to us at Waboba! We're always looking to improve, and your thoughts can help make our supplier portal better for everyone.{" "}
            </p>
            <p>
              Got something you love about our portal? Or maybe something that you think could be better? Let us know. We appreciate your input - it helps us
              grow and serve you better.
            </p>
            <p>Thanks for taking a few moments to share your thoughts with us!</p>
          </div>
          <div className="mt-6">
            <Text
              className=" placeholder:text-center"
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Input your feedback here 请在这里写下你的想法..."
            />
          </div>
          <div className="flex justify-center p-4">
            <Button loading={submitFeedbackRes.loading} onClick={submit}>
              Submit 提交
            </Button>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default Support;
