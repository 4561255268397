import { AppContext } from "AppContainer";
import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import Menu from "./Menu";
import UserView from "./UserView";
import Button from "components/Button";
import { BsLayoutSidebarInset } from "react-icons/bs";

const MainRouteLayout = () => {
  const { user, showMenu, setShowMenu } = useContext(AppContext);

  return (
    <div className="md:flex md:min-h-screen h-screen">
      <nav
        className={`hidden md:flex flex-col w-72 flex-shrink-0 dark:bg-gray-800 dark:border-gray-700 h-screen fixed bg-gray-200 top-0 transition-all duration-300 ease-in-out
        ${showMenu ? "left-0" : "-left-72"}
      `}
      >
        <div className="px-6 border-b dark:border-gray-700">
          <Button className="text-xl pt-6" onClick={() => setShowMenu((prev) => !prev)}>
            <BsLayoutSidebarInset />
          </Button>
          <div className="mt-4">
            <Link to="/" className="dark:text-gray-100 dark:hover:text-gray-100 text-gray-800">
              <h3 className="font-bold">{user.supplier.name}</h3>
            </Link>
          </div>

          {!process.env.REACT_APP_GRAPHQL_URL.includes("s2.waboba.com") ? (
            <div className="bg-pink-500 px-6 py-2 text-sm text-white -mx-6 mt-3">Test Environment</div>
          ) : null}
        </div>

        <div className="flex-1">
          <Menu />
        </div>

        <UserView />
      </nav>
      <div
        className={`flex-1 min-h-screen flex flex-col transition-all duration-300 ease-in-out
          ${showMenu ? "md:ml-72" : ""}`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default MainRouteLayout;
