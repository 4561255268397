import { useQuery, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Page from "components/Page";
import EditView from "./EditView";
import { useModals } from "components/Modal";
import ProductionItem from "./ProductionItem";

const FETCH_SUPPLIER_INFO = gql`
  query FETCH_SUPPLIER_INFO {
    me {
      supplier {
        id
        productionCategories {
          id
          name
          type
          qtyPerDay
          maxCapacity
          timeList {
            qty
            days
          }
          workOnMonday
          workOnTuesday
          workOnWednesday
          workOnThursday
          workOnFriday
          workOnSaturday
          workOnSunday
        }
      }
    }
  }
`;

const ProductionCategories = () => {
  const { code } = useParams();
  const { loading, data, error } = useQuery(FETCH_SUPPLIER_INFO, {
    variables: { code },
  });
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  const productionsinfo = data.me.supplier.productionCategories;

  function editItem(item) {
    modal.present({
      title: `修改生产时间`,
      subtitle: item.name,
      children: <EditView item={item} dismiss={modal.dismiss} />,
    });
  }

  return (
    <Page title="Production" topbarRightView={<Link to="holidays">Holidays 假期</Link>}>
      <div className="m-6 lg:m-8">
        <div className=" opacity-70 text-sm leading-relaxed space-y-2">
          This page is used to manage the production time of various production lines. Production time refers to the time that the supplier can produce. The
          supplier can set the working days of each week and the number of production per day. Keeping the latest production data helps both parties to arrange
          production plans more effectively.
          本页面用于管理各种生产线的生产时间。生产时间是指供应商可以生产的时间，供应商可以设置每周的工作日，以及每天的生产数量。保持最新的生产数据有助于双方更加有效的安排生产计划。
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-10 justify-between w-full mt-6">
          {productionsinfo.map((item) => (
            <ProductionItem key={item.id} production={item} editItem={() => editItem(item)} />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default ProductionCategories;
