import Button from "components/Button";
import { BY_TYPES, WEEK_DAYS } from "./consts";

const ProductionItem = ({ production, editItem }) => {
  return (
    <div className="card space-y-4 flex flex-col flex-1">
      <div className="space-y-4 flex-1">
        <h4>{production.name}</h4>

        <div className="flex justify-between">
          <div>
            <label>计算方法：</label>
            {BY_TYPES[production.type]}
          </div>

          <Button onClick={editItem}>修改</Button>
        </div>

        <hr />

        {production.type === "BY_QTY" ? (
          <div className="-mx-2">
            <table>
              <thead>
                <tr>
                  <th>生产数量</th>
                  <th className="text-right">所需天数</th>
                </tr>
              </thead>
              <tbody>
                {production.timeList.map((i, index) => (
                  <tr key={index}>
                    <td className="py-1">{i.qty}</td>
                    <td className="py-1 text-right">{i.days}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex justify-between">
            <span>{production.qtyPerDay}个 / 天</span>
          </div>
        )}

        <div className=" space-x-3">
          <label htmlFor="">最大月产能:</label>
          <span>{production.maxCapacity} 个 / 月</span>
        </div>
      </div>

      <hr />

      <div>
        <div>
          <label htmlFor="">工作日</label>
        </div>
        <div className="flex justify-between mt-4">
          {Object.entries(WEEK_DAYS).map(([key, value], index) => (
            <div key={index} className={`font-semibold text-sm ${production["workOn" + key] ? "" : " opacity-20"}`}>
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductionItem;
