import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import AppContainer from "./AppContainer";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { apolloClient } from "Apollo";

const contextClass = {
  success:
    "bg-green-500 dark:bg-green-600 bg-opacity-80 dark:bg-opacity-60 backdrop-blur-lg",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
      <ToastContainer
        hideProgressBar={true}
        autoClose={5000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        closeButton={false}
        closeOnClick={true}
        icon={false}
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          " relative flex p-1 min-h-10 rounded-2xl justify-between overflow-hidden cursor-pointer"
        }
        bodyClassName={() => "text-sm font-white font-med block p-4"}
      />
    </ApolloProvider>
  );
}
