import { Link } from "react-router-dom";
import { BsChevronRight, BsTrash } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import Input from "components/Input";
import { FileUploader } from "components/FileSelector";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { SAVE_ASSESSMENT } from "./graphql";
import Button from "components/Button";

const newBaseInfo = {
  name: "",
  address: "",
  district: "",
  phone: "",
  fax: "",
  email: "",
  website: "",
  contactNameAndTitle: "",
  principleProducts: "",
  activeYears: "",
  businessCertificate: [], // urls
  auditCertificates: [], // urls
  factorySize: "",

  workshop: {
    size: "",
    totalMen: "",
    totalWomen: "",
  },
  admin: {
    size: "",
    totalMen: "",
    totalWomen: "",
  },
  quarters: {
    size: "",
    totalPersonEachRoom: "",
    totalTolets: "",
  },
  canteen: {
    size: "",
    totalTables: "",
  },
};

const AssessmentBaseInfo = ({ assessment }) => {
  const totalCategories = assessment.category.children.edges.filter(({ node }) => node.requiredSupplier).length + 1;
  const [baseInfo, setBaseInfo] = useState(assessment.baseInfo ? JSON.parse(assessment.baseInfo) : newBaseInfo);
  console.log("baseInfo", baseInfo);
  const [saveBaseInfo] = useMutation(SAVE_ASSESSMENT, {
    variables: { id: assessment.id, baseInfo: JSON.stringify(baseInfo) },
    onError() {
      toast.error(
        "Auto save base info failed, please try again later. If the problem persists, please contact us. 自动保存基本信息失败，请稍后再试。如果问题仍然存在，请与我们联系。"
      );
    },
  });

  let timerRef = useRef();

  function changeHandler(values) {
    if (timerRef.current) clearTimeout(timerRef.current);
    const newBaseInfo = { ...baseInfo, ...values };
    setBaseInfo(newBaseInfo);
    timerRef.current = setTimeout(() => {
      saveBaseInfo();
    }, 500);
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div className="">
      <div className="px-8 xl:px-10 space-x-4 py-4 sticky top-0 z-10 flex justify-between items-center backdrop-blur bg-opacity-80 bg-gray-100 dark:bg-gray-900 border-b dark:border-gray-800 dark:bg-opacity-80">
        <h4>
          {1} / {totalCategories}. Base Info
        </h4>
        <div className="flex items-center justify-between space-x-4 xl:space-x-6">
          <Link to="./../1" className="flex items-center space-x-2">
            <span>Next</span>
            <span>
              <BsChevronRight size={18} />
            </span>
          </Link>
        </div>
      </div>

      <div className="m-6 lg:m-8 space-y-8">
        <div className="card space-y-4">
          <h4>Base Info 基本信息</h4>
          <div className="flex space-x-3 items-center">
            <label htmlFor="">Name 工厂名称：</label>
            <Input value={baseInfo.name} onChange={(e) => changeHandler({ name: e.target.value })} />
          </div>

          <div className="flex space-x-3 items-center">
            <label htmlFor="">Address 工厂地址：</label>
            <Input value={baseInfo.address} onChange={(e) => changeHandler({ address: e.target.value })} />
          </div>

          <div className="flex space-x-3 items-center">
            <label htmlFor="">District 区域：</label>
            <Input value={baseInfo.district} onChange={(e) => changeHandler({ district: e.target.value })} />
          </div>

          <div className="xl:flex xl:space-x-6 items-center space-y-4 xl:space-y-0">
            <div className="flex flex-1 space-x-3 items-center">
              <label htmlFor="">Phone 电话：</label>
              <Input value={baseInfo.phone} onChange={(e) => changeHandler({ phone: e.target.value })} />
            </div>

            <div className="flex flex-1 space-x-3 items-center">
              <label htmlFor="">Fax 传真：</label>
              <Input value={baseInfo.fax} onChange={(e) => changeHandler({ fax: e.target.value })} />
            </div>

            <div className="flex flex-1 space-x-3 items-center">
              <label htmlFor="">Email 邮箱：</label>
              <Input value={baseInfo.email} onChange={(e) => changeHandler({ email: e.target.value })} />
            </div>
          </div>

          <div className="flex space-x-3 items-center">
            <label htmlFor="">Website 网址：</label>
            <Input value={baseInfo.website} onChange={(e) => changeHandler({ website: e.target.value })} />
          </div>

          <div className="flex space-x-3 items-center">
            <label htmlFor="">Principle Products 主要产品：</label>
            <Input value={baseInfo.principleProducts} onChange={(e) => changeHandler({ principleProducts: e.target.value })} />
          </div>

          <div className="flex space-x-3 items-center">
            <label htmlFor="">Active Years 成立时间：</label>
            <Input value={baseInfo.activeYears} onChange={(e) => changeHandler({ activeYears: e.target.value })} />
          </div>

          <div className="flex space-x-3 items-baseline">
            <label>Business Certificate 营业执照：</label>
            <div className="space-y-2 flex-1">
              <FileUploader
                title="upload 上传"
                onUploaded={(urls) => {
                  changeHandler({ businessCertificate: [...baseInfo.businessCertificate, ...urls] });
                }}
              />
              {baseInfo.businessCertificate.length > 0 && (
                <div className="text-xs font-normal">
                  {baseInfo.businessCertificate.map((url, index) => (
                    <div className="flex space-x-2 items-center py-1 border-b dark:border-gray-700">
                      <Button
                        danger
                        onClick={() => {
                          if (window.confirm("Are you sure you wish to delete this item?"))
                            changeHandler({ businessCertificate: baseInfo.businessCertificate.filter((_, i) => i !== index) });
                        }}
                      >
                        <BsTrash />
                      </Button>
                      <a href={url} key={index} target="_blank" rel="noreferrer">
                        {url}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex space-x-3 items-baseline">
            <label htmlFor="">Audit Certificates 验厂证书：</label>
            <div className="space-y-2 flex-1">
              <FileUploader
                title="upload 上传"
                onUploaded={(urls) => {
                  changeHandler({ auditCertificates: [...baseInfo.auditCertificates, ...urls] });
                }}
              />
              {baseInfo.auditCertificates.length > 0 && (
                <div className="text-xs font-normal">
                  {baseInfo.auditCertificates.map((url, index) => (
                    <div className="flex space-x-2 items-center py-1 border-b dark:border-gray-700">
                      <Button
                        danger
                        onClick={() => {
                          if (window.confirm("Are you sure you wish to delete this item?"))
                            changeHandler({ auditCertificates: baseInfo.auditCertificates.filter((_, i) => i !== index) });
                        }}
                      >
                        <BsTrash />
                      </Button>
                      <a href={url} key={index} target="_blank" rel="noreferrer">
                        {url}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="lg:flex lg:space-x-3 space-y-3 lg:space-y-0 items-center">
            <label htmlFor="">Contact Name and Title 联系人及职位：</label>
            <Input value={baseInfo.contactNameAndTitle} onChange={(e) => changeHandler({ contactNameAndTitle: e.target.value })} />
          </div>
        </div>

        <div className="card space-y-4">
          <h4>Factory Size 工厂规模</h4>
          <div className="xl:flex xl:space-x-3 space-y-4 xl:space-y-0 items-center">
            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Workshop 车间面积(m²):</label>
              <Input value={baseInfo.workshop.size} onChange={(e) => changeHandler({ workshop: { ...baseInfo.workshop, size: e.target.value } })} />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label>Men workers 男工:</label>
              <Input value={baseInfo.workshop.totalMen} onChange={(e) => changeHandler({ workshop: { ...baseInfo.workshop, totalMen: e.target.value } })} />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Women workers 女工:</label>
              <Input value={baseInfo.workshop.totalWomen} onChange={(e) => changeHandler({ workshop: { ...baseInfo.workshop, totalWomen: e.target.value } })} />
            </div>
          </div>

          <div className="xl:flex xl:space-x-3 space-y-4 xl:space-y-0 items-center">
            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Admin 管理部门面积(m²):</label>
              <Input value={baseInfo.admin.size} onChange={(e) => changeHandler({ admin: { ...baseInfo.admin, size: e.target.value } })} />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Men workers 男工:</label>
              <Input value={baseInfo.admin.totalMen} onChange={(e) => changeHandler({ admin: { ...baseInfo.admin, totalMen: e.target.value } })} />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label>Women workers 女工:</label>
              <Input value={baseInfo.admin.totalWomen} onChange={(e) => changeHandler({ admin: { ...baseInfo.admin, totalWomen: e.target.value } })} />
            </div>
          </div>
        </div>

        <div className="card space-y-4">
          <h4>Quarters 员工宿舍</h4>

          <div className="xl:flex xl:space-x-3 space-y-4 xl:space-y-0 items-center">
            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Quarters 宿舍面积(m²):</label>
              <Input value={baseInfo.quarters.size} onChange={(e) => changeHandler({ quarters: { ...baseInfo.quarters, size: e.target.value } })} />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Total person each room 房间人数：</label>
              <Input
                value={baseInfo.quarters.totalPersonEachRoom}
                onChange={(e) => changeHandler({ quarters: { ...baseInfo.quarters, totalPersonEachRoom: e.target.value } })}
              />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Total toilets: </label>
              <Input
                value={baseInfo.quarters.totalTolets}
                onChange={(e) => changeHandler({ quarters: { ...baseInfo.quarters, totalTolets: e.target.value } })}
              />
            </div>
          </div>
        </div>

        <div className="card space-y-4">
          <h4>Canteen 食堂</h4>
          <div className="xl:flex xl:space-x-3 space-y-4 xl:space-y-0 items-center">
            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Canteen 食堂面积(m²):</label>
              <Input value={baseInfo.canteen?.size} onChange={(e) => changeHandler({ canteen: { ...baseInfo.canteen, size: e.target.value } })} />
            </div>

            <div className="flex items-center space-x-3 flex-1">
              <label htmlFor="">Total tables 桌子数：</label>
              <Input value={baseInfo.canteen?.totalTables} onChange={(e) => changeHandler({ canteen: { ...baseInfo.canteen, totalTables: e.target.value } })} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentBaseInfo;
