import { NavLink } from "react-router-dom";
import { BsShieldCheck } from "react-icons/bs";
import { PiPackageLight } from "react-icons/pi";
import { FaGears } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";

const MENUS = [
  { name: "Production", path: "/production", icon: <FaGears size={21} /> },
  { name: "Inventory", path: "/inventory", icon: <PiPackageLight size={24} /> },
  { name: "ESG", path: "/esg", icon: <BsShieldCheck /> },
  { name: "Support", path: "/support", icon: <GoQuestion /> },
];

const Menu = () => {
  return (
    <div className="p-3">
      {MENUS.map((i) => (
        <div key={i.path} className="border-b lg:border-0 dark:border-gray-800">
          <NavLink
            className={({ isActive }) => `
            ${isActive ? "bg-gray-300 dark:bg-gray-700" : ""}
            px-3 py-3 lg:py-2 rounded-xl font-semibold w-full flex space-x-2 items-center text-base
            `}
            to={i.path}
          >
            <span className="w-6 text-xl lg:text-lg">{i.icon}</span>
            <span>{i.name}</span>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default Menu;
