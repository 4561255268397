export const Select = ({ className = "", textAlignLast = "", plain = false, children, ...rest }) => (
  <select
    className={`${className} cursor-pointer max-w-full
           ${
             plain ? "" : "border border-gray-200 dark:border-gray-700 dark:text-gray-200"
           } rounded-lg appearance-none px-4 focus:border-sky-600 disabled:opacity-50 disabled:cursor-not-allowed max-w-full`}
    style={{ textAlignLast }}
    {...rest}
  >
    {children}
  </select>
);
