import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { FETCH_PRESIGNED_URL, uploadFile } from "utils/upload";

function useFileUploader(complete) {
  const [fetchPresignedUrl] = useLazyQuery(FETCH_PRESIGNED_URL);
  const [isUploading, setIsUploading] = useState(false);

  function startUpload(files) {
    setIsUploading(true);
    let promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((onCompleted, onError) => {
          fetchPresignedUrl({ variables: { filename: file.name } })
            .then((res) => uploadFile(res.data.qiniuPresignedUrl, file))
            .then(onCompleted)
            .catch(onError);
        })
      );
    });

    Promise.all(promises)
      .then((urls) => {
        complete(urls);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  return { isUploading, startUpload };
}

export default useFileUploader;
