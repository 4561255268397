export const BY_TYPES = {
  BY_QTY: "按数量",
  BY_DAY: "按天数",
};

export const WEEK_DAYS = {
  Monday: "周一",
  Tuesday: "周二",
  Wednesday: "周三",
  Thursday: "周四",
  Friday: "周五",
  Saturday: "周六",
  Sunday: "周日",
};
