export const Tag = ({ selected = false, children, ...rest }) => {
  return (
    <button
      type="button"
      className={`
              flex items-center text-sm
              border
              outline-none
              focus:outline-none whitespace-nowrap
              rounded-full px-4 py-1
              ${
                selected
                  ? " bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-100 active:text-gray-800"
                  : " hover:bg-blue-600 hover:text-white active:text-white active:bg-blue-700"
              }
           `}
      {...rest}
    >
      {children}
    </button>
  );
};
