import { gql } from "@apollo/client";

export const FETCH_SUPPLIER_ASSESSMENTS = gql`
  query FETCH_SUPPLIER_ASSESSMENTS {
    me {
      id
      supplier {
        id
        assessmentSet {
          edges {
            node {
              id
              createdAt
              status
              dueDate
              baseInfo
              category {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ASSESSMENT_DETAIL = gql`
  query FETCH_ASSESSMENT_DETAIL($id: ID!) {
    assessment(id: $id) {
      id
      dueDate
      createdAt
      supplier {
        id
        name
      }
      baseInfo

      category {
        id
        name
        children {
          edges {
            node {
              id
              name
              requiredSupplier
              children {
                edges {
                  node {
                    id
                    name
                    requiredSupplier
                  }
                }
              }
            }
          }
        }
      }

      questions {
        edges {
          node {
            id
            question {
              id
              category {
                id
              }
              question
              answerType
              numberListCount
              choices {
                id
                choice
                content
              }
            }
            answerText
            answerNumbers
            answerChoices
            uploadedFiles
            answerAt
          }
        }
      }
      status
    }
  }
`;

export const SAVE_ASSESSMENT_QUESTION = gql`
  mutation SAVE_ASSESSMENT_QUESTION($id: ID!, $answerText: String, $answerNumbers: [Float], $answerChoices: [String], $uploadedFiles: [String]) {
    saveAssessmentQuestion(id: $id, answerText: $answerText, answerNumbers: $answerNumbers, answerChoices: $answerChoices, uploadedFiles: $uploadedFiles) {
      assessmentQuestion {
        id
        answerText
        answerNumbers
        answerChoices
        uploadedFiles
        answerAt
      }
    }
  }
`;

export const SUBMIT_ASSESSMENT = gql`
  mutation SUBMIT_ASSESSMENT($id: ID!, $status: String!) {
    updateAssessmentStatus(id: $id, status: $status) {
      assessment {
        id
        status
      }
    }
  }
`;

export const SAVE_ASSESSMENT = gql`
  mutation SAVE_ASSESSMENT($id: ID!, $baseInfo: String!) {
    saveAssessment(id: $id, baseInfo: $baseInfo) {
      assessment {
        id
        baseInfo
      }
    }
  }
`;
