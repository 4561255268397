import { useMutation } from "@apollo/client";
import { parseError } from "Apollo";
import Button from "components/Button";
import { FileSelector } from "components/FileSelector";
import Text from "components/Text";
import useFileUploader from "hooks/useFileUploader";
import { useState } from "react";
import { BsTrash, BsUpload } from "react-icons/bs";
import { toast } from "react-toastify";
import { SUBMIT_RECTIFICATION } from "./graphql";
import { IoIosSend } from "react-icons/io";

const SubmitRectificationView = ({ assessmentQuestion, dismiss }) => {
  const [content, setContent] = useState("");
  const [files, setFiles] = useState(assessmentQuestion.latestRectification ? assessmentQuestion.latestRectification.files : []);

  const [submitRectification, submitRectificationRes] = useMutation(SUBMIT_RECTIFICATION, {
    onCompleted: () => {
      toast.success("Rectification submitted 整改提交成功");
      setContent("");
      setFiles([]);
      dismiss();
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  const { isUploading, startUpload } = useFileUploader((urls) => {
    setFiles([...files, ...urls]);
  });

  function tryDeleteFile(url) {
    setFiles(files.filter((f) => f !== url));
  }

  function submitHandler() {
    submitRectification({
      variables: { assessmentQuestionId: assessmentQuestion.id, content, files },
    });
  }

  return (
    <div>
      <div>
        <div>Please add your rectifitcation below. 请输入您的实际整改方案或结果.</div>
        <div className="mt-2">
          <Text required value={content} onChange={(e) => setContent(e.target.value)} />
        </div>
      </div>
      <div>
        {files.length > 0 ? (
          <>
            <div className="text-xs space-y-2 my-2">
              {files.map((url, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <Button danger onClick={() => tryDeleteFile(url)}>
                    <BsTrash />
                  </Button>

                  <a href={url} target="_blank" rel="noreferrer">
                    {url}
                  </a>
                </div>
              ))}
            </div>
          </>
        ) : null}

        <FileSelector
          className="text-sm mt-2"
          uploading={isUploading}
          title={
            <div className="flex items-center space-x-2">
              <span>
                <BsUpload />
              </span>
              <span>Upload file / image 上传文件或图片</span>
            </div>
          }
          onChange={(e) => {
            const files = [...e.target.files];
            startUpload(files);
            e.target.value = null;
          }}
        />
      </div>

      <div className="mt-4">
        <Button loading={submitRectificationRes.loading} onClick={submitHandler}>
          <div className="flex space-x-2 items-center">
            <span>Submit 提交整改</span>
            <span>
              <IoIosSend size={21} />
            </span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SubmitRectificationView;
