import { Route, Routes } from "react-router-dom";
import ProductionCategories from "./Categories";
import Holidays from "./Holidays";

const ProductionPage = () => {
  return (
    <Routes>
      <Route index element={<ProductionCategories />} />
      <Route path="holidays" element={<Holidays />} />
    </Routes>
  );
};

export default ProductionPage;
