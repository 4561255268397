import { AppContext } from "AppContainer";
import Button from "components/Button";
import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "auth/graphql";
import { toast } from "react-toastify";

const UserView = () => {
  const { user } = useContext(AppContext);

  const [signOut, signOutRes] = useMutation(LOGOUT, {
    onCompleted() {
      window.location.href = "/login";
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <div className="px-6 py-4 border-t dark:border-gray-700 sticky bottom-0">
      <div className="opacity-70 text-sm">{user.email}</div>
      <div className="mt-1">
        <Button danger className="opacity-70" onClick={signOut} loading={signOutRes.loading}>
          Sign Out
        </Button>
      </div>
    </div>
  );
};

export default UserView;
