import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation LOGIN($device: String!, $email: String!, $password: String!) {
    secureLogin(device: $device, email: $email, password: $password) {
      userId
      enableMfa
      loginDirectly
      me {
        id
        email
        supplier {
          id
          name
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      isLogout
    }
  }
`;
