import Page from "components/Page";
import Button from "components/Button";
import { useContext, useState } from "react";
import { useModals } from "components/Modal";
import ProductSelector from "./ProductSelector";
import { BsTrash } from "react-icons/bs";
import Input from "components/Input";
import { CREATE_SUPPLIER_STOCK_UPDATE_REQUEST } from "./graphql";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { parseError } from "Apollo";
import { AppContext } from "AppContainer";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SubmitDeliveryPage = () => {
  const { user } = useContext(AppContext);
  const [deliveries, setDeliveries] = useState([]);
  const modal = useModals();
  const navigate = useNavigate();

  const [submit, submitRes] = useMutation(
    CREATE_SUPPLIER_STOCK_UPDATE_REQUEST,
    {
      onCompleted() {
        toast.success(
          "Submitted successfully. We will review and update the inventory number accordingly. 提交成功。我们将审核并相应更新库存数量。更新完成之后你将会在库存页面中看到更新后的库存数量。",
          { autoClose: false },
        );
        navigate("./..");
      },
      onError(error) {
        toast.error(parseError(error));
      },
    },
  );

  function submitHandler() {
    if (window.confirm("Are you sure you want to submit?")) {
      const products = deliveries.map((i) => ({
        id: i.product.id,
        qty: parseInt(i.qty),
        readyDate: i.date,
        remark: i.remark,
        updateType: "Submit Inventory",
      }));
      const variables = {
        supplierId: user.supplier.id,
        data: JSON.stringify({ products }),
      };
      submit({ variables });
    }
  }

  function showProductsSelector() {
    modal.present({
      title: "Select Product",
      children: (
        <ProductSelector
          onSelect={(product) => {
            setDeliveries([
              ...deliveries,
              {
                product,
                qty: 0,
                date: moment().format("YYYY-MM-DD"),
                remark: "",
              },
            ]);
            modal.dismiss();
          }}
        />
      ),
    });
  }

  return (
    <Page title="Submit Inventory 提交库存" backTo="./..">
      <div className="m-8">
        <div className="card">
          <div className="-mx-2">
            <table>
              <thead>
                <tr>
                  <th>Product / 产品</th>
                  <th>Date / 日期</th>
                  <th className="text-right">QTY / 数量</th>
                  <th>Remark / 备注</th>
                </tr>
              </thead>
              <tbody>
                {deliveries.map((delivery, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex space-x-2 items-center">
                        <Button
                          danger
                          onClick={() =>
                            setDeliveries((prev) =>
                              prev.filter(
                                (_, prevIndex) => prevIndex !== index,
                              ),
                            )
                          }
                        >
                          <BsTrash />
                        </Button>
                        <div>
                          [{delivery.product.number}] {delivery.product.name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <Input
                        type="date"
                        value={delivery.date}
                        onChange={(e) =>
                          setDeliveries((prev) =>
                            prev.map((i, iIndex) =>
                              iIndex === index
                                ? {
                                    ...i,
                                    date: moment(e.target.value).format(
                                      "YYYY-MM-DD",
                                    ),
                                  }
                                : i,
                            ),
                          )
                        }
                      />
                    </td>
                    <td className="text-right">
                      <Input
                        className="text-right"
                        type="number"
                        value={delivery.qty}
                        onChange={(e) =>
                          setDeliveries((prev) =>
                            prev.map((i, iIndex) =>
                              iIndex === index
                                ? { ...i, qty: e.target.value }
                                : i,
                            ),
                          )
                        }
                      />
                    </td>
                    <td>
                      <Input
                        value={delivery.remark}
                        onChange={(e) =>
                          setDeliveries((prev) =>
                            prev.map((i, iIndex) =>
                              iIndex === index
                                ? { ...i, remark: e.target.value }
                                : i,
                            ),
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button onClick={showProductsSelector}>
                      + Add Product 添加产品
                    </Button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className=" mt-4">
            <div className="text-sm opacity-70">
              Add product to the list and fill in the date, quantity, and
              remark. Submit to us when you are ready. Make sure the information
              is correct. We will review and update the inventory number
              accordingly.
              请添加产品到列表中，并填写日期、数量和备注。当您准备好时提交给我们。请确保信息正确。我们将审核并相应更新库存数量。我们更新完成之后你将会在库存页面中看到更新后的库存数量。
            </div>

            <div className="mt-4">
              <Button
                primary
                onClick={submitHandler}
                loading={submitRes.loading}
              >
                Submit 提交
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SubmitDeliveryPage;
