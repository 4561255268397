import useFileUploader from "hooks/useFileUploader";
import { useState } from "react";
import { v4 } from "uuid";

export const FileSelector = ({ className = "", title = "add files", bold = false, bgColor = "", onChange, uploading, ...rest }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [uid] = useState(v4());
  const borderStyle = "text-blue-600";
  const hoverBorderStyle = "text-blue-700";
  return (
    <div className={`${className} overflow-hidden relative`} onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)}>
      <label
        className={`relative
                 ${bold ? "font-bold" : "font-normal"}
                 ${rest.disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
                 ${borderStyle}
                 ${mouseIn ? hoverBorderStyle : ""}
                 ${bgColor}
                 `}
        htmlFor={uid}
      >
        {uploading ? "uploading..." : title}
      </label>

      <input id={uid} className="hidden" type="file" multiple required onChange={onChange} disabled={uploading || rest.disabled} {...rest} />
    </div>
  );
};

export const FileUploader = ({ title = "Upload files", onUploaded, ...rest }) => {
  const { isUploading, startUpload } = useFileUploader(onUploaded);

  return (
    <FileSelector
      uploading={isUploading}
      title={title}
      {...rest}
      onChange={(e) => {
        const files = [...e.target.files];
        startUpload(files);
      }}
    />
  );
};
